import { render, staticRenderFns } from "./printerForm.vue?vue&type=template&id=4bafa856&scoped=true&"
import script from "./printerForm.vue?vue&type=script&lang=js&"
export * from "./printerForm.vue?vue&type=script&lang=js&"
import style0 from "./printerForm.vue?vue&type=style&index=0&id=4bafa856&prod&lang=scss&scoped=true&"
import style1 from "./printerForm.vue?vue&type=style&index=1&id=4bafa856&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bafa856",
  null
  
)

export default component.exports