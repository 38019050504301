<template>
    <div class="form-container hide-scrollbar" :class="[$mq]">
        <div class="form-title">
            {{ userID ? $t('config.users.edit') : $t('config.users.create') }}
        </div>
        <div class="form-content">
            <div class="field-form">
                <div class="label">
                    {{ $t('config.users.name') }}
                </div>

                <input type="text" v-model="user.name" class="input" :class="{ error: errors.name }" />
            </div>
            <div class="field-form">
                <div class="label">
                    {{ $t('config.users.surname') }}
                </div>

                <input type="text" :class="{ error: errors.surname }" v-model="user.surname" />
            </div>
            <div class="field-form" v-if="Object.values(roles).length > 0">
                <div class="label">
                    {{ $t('config.users.role') }}
                </div>

                <select :disabled="employeeLogged.id == userID.id" v-model="user.rol" class="select" :class="[{ active: selectOpened }, { error: errors.rol }, { disabled: employeeLogged.id == userID.id }]" @click="selectOpened = !selectOpened">
                    <option v-for="rol in roles" :key="rol.id" :value="rol.id">{{ rol.name }}</option>
                </select>
            </div>
            <div class="field-form">
                <div class="label">
                    {{ $t('config.users.pin') }}
                </div>

                <div class="password-container" v-if="user.password ? user.password.toString().length < 5 : true">
                    <input
                        min="0"
                        type="number"
                        @change="
                            () => {
                                if (user.password.toString().length > 4 || user.password.toString().length < 0) {
                                    this.user.password = this.user.password.toString().slice(0, 4)
                                }
                            }
                        "
                        oninput="validity.valid||(value='');"
                        :class="{ error: errors.password }"
                        v-model="user.password"
                        class="password"
                    />
                    <div class="random-password" @click="fourRandomNumbers()"></div>
                </div>
                <div class="reset" v-else>
                    <div class="btn cancel" @click="changePass()">{{ $t('config.user.change_pin') }}</div>
                </div>
            </div>
        </div>
        <div class="footer-actions">
            <div class="btn cancel" @click="cancel()">{{ $t('tickets.cancel') }}</div>
            <div class="btn" @click="validateUser()">{{ $t('label.print.save') }}</div>
        </div>
    </div>
</template>

<script>
import Message from '@/components/domain/ui/Message'

export default {
    components: {
        Message
    },
    props: {
        userID: {
            type: undefined,
            default: false
        },
        employeeLogged: {
            type: undefined,
            default: false
        }
    },
    name: 'UsersForm',
    data() {
        return {
            user: {
                name: '',
                surname: '',
                rol: '',
                password: ''
            },
            errors: {
                name: false,
                surname: false,
                rol: false,
                password: false
            },
            selectOpened: false
        }
    },
    computed: {
        roles() {
            var roles = Object.values(this.$store.getters['employee/getAllRoles'])
            var result = []
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].level <= 3) {
                    result.push(roles[i])
                }
                if (this.employeeLogged) {
                    if (this.employeeLogged.id == this.userID.id && this.employeeLogged.rol == roles[i].id) {
                        result.push(roles[i])
                    }
                }
            }
            result = result.sort(function(a, b) {
                return a.level - b.level
            })
            return result
        },
        employeeEdit() {
            if (this.userID) {
                return this.$store.getters['employee/getEmployee'](this.userID.id)
            }
        }
    },
    methods: {
        cancel() {
            var self = this
            // Añadir nuevo usuario
            if (!this.userID) {
                var user = {
                    name: '',
                    surname: '',
                    rol: Object.values(this.roles)[0].id,
                    password: ''
                }
                // Comprobar si ha hecho cambios en el formulario de añdir
                if (JSON.stringify(this.user) !== JSON.stringify(user)) {
                    self.$popup.confirm({
                        message: self.$t('config.users.cancel_add'),
                        textSave: self.$t('popup.label_print.continue'),
                        textCancel: self.$t('register.cancel_complete'),
                        callSave: function() {
                            self.$popup.close()
                        },
                        callCancel: function() {
                            self.$emit('cancel')
                            self.$popup.close()
                        }
                    })
                } else {
                    this.$emit('cancel')
                }
                // Comprobar que al editar usuario ha hecho cambios
            } else if (JSON.stringify(this.user) !== JSON.stringify(this.employeeEdit) && this.userID) {
                self.$popup.confirm({
                    message: self.$t('config.users.cancel_add'),
                    textSave: self.$t('popup.label_print.continue'),
                    textCancel: self.$t('register.cancel_complete'),
                    callSave: function() {
                        self.$popup.close()
                    },
                    callCancel: function() {
                        self.$emit('cancel')
                        self.$popup.close()
                    }
                })
            } else {
                this.$emit('cancel')
            }
        },
        changePass() {
            this.user.password = ''
        },
        fourRandomNumbers() {
            var numbers = []
            for (var i = 0; i < 4; i++) {
                numbers.push(Math.floor(Math.random() * 10))
            }
            return (this.user.password = numbers.join(''))
        },
        validateUser() {
            if (fnCheckConnection()) {
                var user = this.user
                if (user.name == '') {
                    this.errors.name = this.$t('config.users.name_required')
                } else {
                    this.errors.name = false
                }
                // if (user.surname == '') {
                //     this.errors.surname = this.$t('config.users.surname_required')
                // } else {
                //     this.errors.surname = false
                // }
                if (user.rol == '') {
                    this.errors.rol = this.$t('config.users.role_required')
                } else {
                    this.errors.rol = false
                }
                if (user.password.toString().length < 4) {
                    this.errors.password = this.$t('config.users.pin_required')
                } else {
                    this.errors.password = false
                }
                if (Object.values(this.errors).filter(error => error != false).length > 0) {
                    this.$snackbar.error({
                        duration: 10000,
                        closeable: true,
                        message:
                            '<ul>' +
                            Object.values(this.errors)
                                .filter(Boolean)
                                .join(' .', this.errors) +
                            '</ul>'
                    })
                } else {
                    this.saveEmployee()
                }
            } else {
                this.$snackbar.error({ duration: 10000, closeable: true, message: this.$t('config.users.no_internet') })
            }
        },
        saveEmployee() {
            var self = this
            self.$overlay.show()
            if (this.userID) {
                if (this.user.password.length == 4) {
                    this.user.pin = sha256(this.user.password)
                    delete this.user.password
                } else {
                    this.user.pin = this.user.password
                    delete this.user.password
                }
                this.$store.dispatch('employee/editEmployee', this.user).then(function(response) {
                    if (response) {
                        self.$emit('cancel')
                        self.$overlay.hide()
                        self.$snackbar.success({ duration: 3000, closeable: true, message: self.$t('config.users.edit_success', { name: self.user.name }) })
                    }
                })
            } else {
                this.$store.dispatch('employee/addNewEmployee', this.user).then(function(response) {
                    if (response) {
                        self.$emit('cancel')
                        self.$overlay.hide()
                        self.$snackbar.success({ duration: 3000, closeable: true, message: self.$t('config.users.add_success', { name: self.user.name }) })
                    }
                })
            }
        }
    },
    mounted() {
        if (this.userID) {
            this.user = this.userID
        }
    },
    created() {
        // Assign first role at create user by default
        if (!this.userID && this.user.rol == '' && Object.values(this.roles).length > 0) {
            this.user.rol = Object.values(this.roles)[0].id
        }
    }
}
</script>

<style lang="scss" scoped>
.form-container {
    @include display-flex();
    @include flex-direction(column);
    @include justify-content(flex-start);
    width: 100%;
    height: 100%;
    overflow: auto;

    .form-title {
        @include font-size(l);
        font-family: $text-bold;
        color: $neutro-s90;
        align-self: center;
        padding-top: 20px;
    }
    .form-content {
        @include display-flex();
        @include justify-content();
        @include flex-direction(column);
        @include align-items(center);
        .field-form {
            @include display-flex();
            @include flex-direction(column);
            @include justify-content(center);
            @include align-content(center);
            @include align-items(center);
            width: 100%;
            max-width: 600px;
            .label {
                width: 100%;
                @include font-size(m);
                font-family: $text;
                color: $neutro-s60;
                text-align: left;
                max-width: 600px;
                padding: 9px 0px 6px 0px;
            }
            input {
                @include border-radius(4px);
                @include font-size(m);
                font-family: $text;
                color: $neutro-s70;
                width: 100%;
                max-width: 600px;
                border: none;
                min-height: 40px;
                height: 50px;
                padding: 3px 12px;
                cursor: text;

                &::placeholder {
                    @include font-size(m);
                    color: $neutro-s30;
                    font-family: $text;
                    font-style: normal;
                }
                &.error {
                    border: 1px solid $error;
                }
            }
            .select {
                @include border-radius(4px);
                @include font-size(m);
                background-image: url('../../../../../public/img/down_dark.svg');
                background-size: 12px;
                background-position: right 10px center;
                font-family: $text;
                color: $neutro-s70;
                width: 100%;
                max-width: 600px;
                border: none;
                min-height: 40px;
                height: 50px;
                padding: 3px 12px;
                cursor: text;

                &.active {
                    background-image: url('../../../../../public/img/up_dark.svg');
                    background-size: 12px;
                    background-position: right 10px center;
                }
                &.error {
                    border: 1px solid $error;
                }
                &.disabled {
                    background-color: $neutro-s10;
                    cursor: not-allowed;
                }
            }
            .password-container {
                width: 100%;
                max-width: 600px;
                @include display-flex();
                @include align-items(center);
                padding-bottom: 10px;

                .password {
                    width: calc(100% - 60px);
                }
                .random-password {
                    @extend .interaction;
                    @include border-radius(4px);
                    width: 50px;
                    height: 50px;
                    background-color: $neutro;
                    margin-left: 10px;
                    background-image: url('../../../../../public/img/refresh_main.svg');
                    background-size: 35px;
                    background-position: center center;
                    background-repeat: no-repeat;

                    &:hover {
                        @include bgHover($neutro);
                    }
                }
            }
            .reset {
                width: 100%;
                max-width: 600px;
                padding-bottom: 10px;
                .btn {
                    @include font-size(m);
                    font-family: $text-bold;
                    height: 50px;
                    width: fit-content;
                    text-align: center;

                    &.cancel {
                        background-color: $neutro;
                        color: $main;
                    }
                }
            }
            .message-container {
                width: 100%;
                max-width: 600px;
                padding-left: 8px;
            }
        }
    }
    .footer-actions {
        @include display-flex();
        @include justify-content(center);
        @include align-items(center);
        @include align-content(center);
        @include align-self(center);
        width: 100%;
        height: 50px;
        margin-top: auto;
        gap: 15px;
        max-width: 600px;

        .btn {
            @include font-size(m);
            font-family: $text-bold;
            height: 50px;
            width: 50%;
            text-align: center;
            background-color: $main-dark;

            &.cancel {
                background-color: $neutro;
                color: $main;

                &:hover {
                    @include bgHover($neutro);
                }
            }
            &:hover {
                @include bgHover($main);
            }
        }
    }
    &.landscape {
        padding: 10px;

        .field-form {
            .document {
                width: 100%;
            }
        }
        .footer-actions {
            .btn {
                height: 40px;
            }
        }
    }
    &.desktop {
        padding: 10px;
        .field-form {
            .document {
                width: 100%;
                padding: 0px;
            }
        }
    }
}
</style>
